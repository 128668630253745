<style>
.ivu-table .table-invalid-row td {
  color: #777 !important;
}
.ivu-table .ivu-table-row-hover td {
  color: #aaa !important;
}
</style>

<template>
  <div>
    <Row :gutter="8">
      <i-col :xs="24" :sm="12" :md="6" :lg="4" class="m-b-5">
        <Select size="small" v-model="companyId" placeholder="收款方" clearable>
          <Option
            v-for="(channel, index) in channellist"
            :key="index"
            :value="channel.id"
            >{{ channel.name }}</Option
          >
        </Select>
      </i-col>
      <i-col :xs="24" :sm="12" :md="6" :lg="4" class="m-b-5">
        <DatePicker
          placeholder="录入日期"
          v-model="createDate"
          size="small"
          type="daterange"
          style="width: 100%"
        ></DatePicker>
      </i-col>
      <i-col :xs="24" :sm="12" :md="6" :lg="4" class="m-b-5">
        <DatePicker
          placeholder="收付日期"
          v-model="paymentDate"
          size="small"
          type="daterange"
          style="width: 100%"
        ></DatePicker>
      </i-col>
      <i-col :xs="24" :sm="12" :md="6" :lg="4" class="m-b-5">
        <Select
          style="width: 100%"
          v-model="query.direction"
          size="small"
          clearable
          placeholder="类型"
        >
          <Option value="1">收款</Option>
          <Option value="-1">付款</Option>
        </Select>
      </i-col>
      <i-col :xs="24" :sm="12" :md="6" :lg="4" class="m-b-5">
        <i-input
          placeholder="合同编号、合同名称、甲方"
          v-model="query.keyword"
          size="small"
        ></i-input>
      </i-col>
      <i-col
        :xs="24"
        :sm="6"
        :md="6"
        :lg="4"
        class="m-b-5"
        style="min-width: 150px"
      >
        <i-button
          class="m-r-5"
          type="primary"
          icon="ios-search"
          size="small"
          @click="handleSearch()"
          >搜索</i-button
        >
        <i-button
          type="success"
          size="small"
          v-if="isAuth('payment_download')"
          @click="handleExportExcel"
          >导出Excel</i-button
        >
      </i-col>
    </Row>

    <Table
      stripe
      :data="contractList"
      :columns="tableColumns"
      :row-class-name="rowClassName"
    ></Table>
    <div class="paging_style">
      <Page
        size="small"
        :total="total"
        :page-size="query.pageSize"
        show-total
        show-elevator
        :current="query.pageNumber"
        @on-change="handlePageChanged"
      ></Page>
    </div>
    <Modal  v-model="filesModal"
    title="附件列表"
      width="600"
      :mask-closable="false"
      footer-hide>
      <Files
            :fileList="fileArray"
            :downloadUrl="'/ooh-scp/v3/debtgroupcashflow/downloadFile'"
          ></Files>
    </Modal>
  </div>
</template>

<script>
import { toMoney } from '@/utils/wnumb_own'
import Files from '@/components/common/FilesV2'
import {
  getdebtgroupcashflowpage,
  getdebtgroupcashflowsummary,
  invaliddebtgroupcashflow
} from '@/api/scp/debtgroupcashflow'
import { getCompanyChild } from '@/api/os/company'
import { downloadFileRequest } from '@/utils/download'
export default {
  props: {
    params: Object
  },
  components: { Files },
  data () {
    return {
      query: {
        direction: '',
        keyword: '',
        pageNumber: 1,
        pageSize: 15
      },
      total: 0,
      companyId: '',
      channellist: [],
      createDate: '',
      paymentDate: '',
      taskTypes: [],
      contractList: [],
      tableColumns: [
        {
          title: '合同编号',
          key: 'relateCode'
        },
        {
          title: '合同名称',
          key: 'relateName'
        },
        {
          title: '甲方',
          key: 'relatePartyName'
        },
        {
          title: '收款方',
          key: 'receiverAccountName'
        },
        {
          title: '收付日期',
          key: 'date'
        },
        {
          title: '备注',
          key: 'mark'
        },
        {
          title: '收付金额',
          key: 'amount',
          render: (h, params) => {
            return h('div', [
              h('span', { class: 'money' }, toMoney(params.row.amount))
            ])
          }
        },
        {
          title: '收付类型',
          key: 'directionName',
          render: (h, params) => {
            return h('div', [
              h(
                'span',
                {
                  // style: {
                  //   color: params.row.direction === 1 ? 'seagreen' : '#ed4014'
                  // }
                },
                params.row.directionName
              )
            ])
          }
        },
        {
          title: '录入时间',
          key: 'createTime'
        },
        {
          title: '操作',
          key: 'action',
          align: 'center',
          render: (h, params) => {
            return h('div', [
              params.row.cashflowFileVOList.length
                ? h(
                  'a',
                  {
                    style: { marginRight: '5px' },
                    on: {
                      click: () => {
                        this.fileArray = params.row.cashflowFileVOList.map(x => {
                          return Object.assign({}, x, { url: process.env.VUE_APP_API_URL_V2 + '/ooh-scp/v3/debtgroupcashflow/downloadFile?fileId=' + x.id })
                        })

                        this.filesModal = true
                        // this.handleInvalid(params.row)
                      }
                    }
                  },
                  '附件信息'
                )
                : null,
              params.row.status === 1
                ? this.isAuth('payment_invalid')
                  ? h(
                    'a',
                    {
                      style: { marginRight: '5px', color: '#ef4f4f' },
                      on: {
                        click: () => {
                          this.handleInvalid(params.row)
                        }
                      }
                    },
                    '作废'
                  )
                  : h(
                    'span',
                    {
                      style: { marginRight: '5px' }
                    },
                    '-'
                  )
                : h(
                  'span',
                  {
                    style: { marginRight: '5px' }
                  },
                  '已作废'
                )
            ])
          }
        }
      ],

      filesModal: false,
      fileArray: []
    }
  },
  created () {
    getCompanyChild({
      companyId: this.$store.getters.token.userInfo.companyId,
      type:
        this.$store.getters.token.userInfo.publisherId ===
        this.$store.getters.token.userInfo.companyId
          ? 1
          : 2,
      childType: 2,
      companyType: 1
    }).then((res) => {
      let array = [
        {
          id: res.companyId,
          name: res.companyName
        }
      ]
      if (res.children.length) {
        array = array.concat(
          this.formatCompanyArray(res.children).filter(
            (x) => x.id !== res.companyId
          )
        )
      }
      this.channellist = array
    })
    this.initPageData()
  },

  methods: {
    // 重组公司列表结构
    formatCompanyArray (companyTree) {
      let resultArray = []
      companyTree.forEach(item => {
        resultArray.push({ id: item.companyId, name: item.companyName })
        if (item.children.length) {
          resultArray = resultArray.concat(this.formatCompanyArray(item.children))
        }
      })
      return resultArray
    },
    rowClassName (row, index) {
      if (row.status === -1) {
        return 'table-invalid-row'
      }
      return ''
    },
    formatMoney (number) {
      return toMoney(number)
    },
    initGroupSummary () {
      getdebtgroupcashflowsummary(this.query).then((res) => {
        this.$store.commit('set_payment_contractSummary', res)
        this.$store.commit('set_payment_summaryType', '2')
      })
    },
    handleSearch () {
      this.query.pageNumber = 1
      this.query.minCreateDate = this.formatDate(this.createDate[0])
      this.query.maxCreateDate = this.formatDate(this.createDate[1])
      this.query.startDate = this.formatDate(this.paymentDate[0])
      this.query.endDate = this.formatDate(this.paymentDate[1])
      this.initPageData()
    },
    formatDate (date) {
      if (!date) {
        return null
      } else {
        return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
      }
    },
    handleInvalid (item) {
      let desc = ''
      this.$Modal.confirm({
        title: '作废收付信息？',
        render: (h) => {
          return h('Input', {
            attrs: {
              placeholder: '请输入作废原因'
            },
            props: {
              value: desc
            },
            on: {
              'on-change': (event) => {
                desc = event.target.value
              }
            }
          })
        },
        onOk: () => {
          invaliddebtgroupcashflow({
            cashflowId: item.id,
            invalidMark: desc
          }).then((res) => {
            if (res && !res.errcode) {
              this.$Notice.success({ desc: '作废成功' })
              this.initPageData()
            }
          })
        }
      })
    },
    handlePageChanged (page) {
      this.query.pageNumber = page
      this.initPageData()
    },
    initPageData () {
      this.initGroupSummary()
      getdebtgroupcashflowpage(this.query).then((res) => {
        this.contractList = res.list
        this.total = res.totalRow
      })
    },
    handleExportExcel () {
      const query = Object.assign({}, this.query)
      downloadFileRequest(
        process.env.VUE_APP_API_URL_V2 +
          '/ooh-scp/v3/debtgroupcashflow/exportdebtgroupcashflowexcel',
        query,
        '收付款明细列表.xlsx'
      )
    }
  }
}
</script>
